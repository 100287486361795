import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "button" ]

  confirmLogout(event) {
    event.preventDefault();

    if(!confirm("Are you sure?")) {
      return;  // Early return if user clicks "Cancel"
    }

    const path = '/session';
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    fetch(path, {
      method: 'DELETE',
      headers: {
        'X-Requested-With': 'XMLHttpRequest', // To mimic Rails UJS
        'Accept': 'text/javascript',
        'Content-Type': 'text/javascript',
        'X-CSRF-Token': csrfToken
      }
    })
    .then(function(response) {
      if (!response.ok) {
        throw new Error(`Unexpected HTTP error! status: ${response.status}`);
      }
      return response.text();
    })
    .then(function(js) {
      eval(js); // if the response is a script, evaluate it
    })
    .catch(function(error) {
      console.error('Fetch error:', error);
    });
  }
}
