import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  modifyImageSrcHover() {
    this.element.src = this.element.dataset.imageHover
  }

  modifyImageSrcDefault() {
    this.element.src = this.element.dataset.imageDefault
  }

}