import { FetchRequest } from '@rails/request.js'
import {initializeStripeElements} from '../packs/stripe.js'

export {
  addErrorBorder,
  appendPatientToShippingAddress,
  clearPatientForm,
  clearShippingAddressForm,
  closePatientForm,
  displayPatientCard,
  displayPaymentModal,
  enableDisableNextDayNotice,
  hasEmptyRequiredFields,
  validatePhoneNumber,
  orderOriginPresent,
  orderOriginIsSto,
  orderOriginIsT2oStp,
  orderOriginIsWtp,
  removeErrorBorder,
  resetRequestType,
  toggleOffElementById,
  toggleOnElementById,
  toggleSpinnerModal,
  updateOrderFormWithPatient,
  scrollToError,
  showErrorFlash,
  renderSelectedPatientInfo,
  resetTax,
  submitOrder
}

const enableDisableNextDayNotice = () => {
  if (!isPresent(document.getElementById('next_day_notice'))) return
  if (document.getElementById('order_shipping_method').value == 'P') {
    toggleOnElementById('next_day_notice')
  } else {
    toggleOffElementById('next_day_notice')
  }
}

const updateOrderFormWithPatient = (patient) => {
  if (orderOriginPresent()) {
    renderSelectedPatientInfo(patient)
    updateOrderHiddenField(patient)
    updateOrderWithPatient(patient)
  }
}

const updateOrderWithPatient = async (patient) => {
  const orderId = document.getElementById('address-id-holder').dataset.id
  const patientId = document.getElementById('order_patient_id').value
  if (!isPresent(orderId) || !isPresent(patientId)) return

  const request = new FetchRequest('post', `/update_order_with_selected_patient/?id=${orderId}&patient_id=${patientId}`)
  const response = await request.perform()
  if (response.ok) { updateOrderCart(patient) }
}

const clearShippingAddressForm = () => {
  if (!orderOriginPresent()) return
  const verifyAddress = document.getElementById('verify_address')
  const verifyLink = document.querySelectorAll('#verify_add a')[0]
  const shippingAddressIdArray = [
    'order_patient_id',
    'order_patient_info',
    'order_patient_first_name',
    'order_patient_last_name',
    'order_name_2',
    'order_street_address_1',
    'order_street_address_2',
    'order_city',
    'order_state',
    'order_zip',
  ]
  for (let i = 0; i < shippingAddressIdArray.length; i++) {
    document.getElementById(`${shippingAddressIdArray[i]}`).value = '' 
  }
  if (verifyAddress.checked) { verifyAddress.click() }
  if (isPresent(document.querySelectorAll('.validated_addresses h3')[0])) { verifyLink.click() } 
}

const updateOrderHiddenField = (patient) => {
  const patientElement = document.getElementById('order_patient_id')
  if (!isPresent(patientElement)) return
  patientElement.value = patient.id
}

const updateOrderCart = (patient) => {
  const orderId = document.getElementById('address-id-holder').dataset.id
  if (!isPresent(orderId)) return

  // patient NOT present
  const stpTxtToOrderCheckbox = document.getElementById('enable_txt2order')
  if (isPresent(stpTxtToOrderCheckbox)) {
    if (!isPresent('order_origin') || !stpTxtToOrderCheckbox.checked) return
  }

  checkAndUpdateCart(orderId)
}

const checkAndUpdateCart = async (orderId) => {
  const request = new FetchRequest('get', `/check_if_cart_empty/?id=${orderId}&patient_id=${document.getElementById('order_patient_id').value}`)
  const response = await request.perform()
  const data = await response.json
  if (response.ok) { 
    if (!data.cart) return // order_details NOT present
    toggleCartInfoChangeModal()
    updatePatientInfoInCart(data) // modifies order_detail.patient_info to selected patient's info
  } else {
    alertErrorFlash('Error occurred while updating patient info in cart,')
  }
}

const updatePatientInfoInCart = (details) => {
  const cartPatientRight = document.getElementsByClassName('cart_patient_info_right')
  const cartPatientLeft = document.getElementsByClassName('cart_patient_info_left')
  for (let i = 0; i < cartPatientRight.length; i++) { cartPatientRight[i].innerText = truncatePatientInfo(details.patient_info_right) }
  for (let i = 0; i < cartPatientLeft.length; i++) { cartPatientLeft[i].innerText = truncatePatientInfo(details.patient_info_left) }
}

const truncatePatientInfo = (string) => {
  const maxCharLength = 30
  if (string.length <= maxCharLength) return string
  return string.substring(0, maxCharLength)
}

const hasEmptyRequiredFields = () => {
  // add red error borders to empty required patient fields
  let errors = []
  const required = document.getElementsByClassName('patient-input required')
  const orderOrigin = document.getElementById('order_origin')

  for(let i = 0; i < required.length; i++) {
    if (required[i].id == 'patient_text_consent') {
      if (!orderOriginPresent()) continue
      if (requireTextToOrderConsent(orderOrigin)) {
        errors.push(required[i])
      }
    } else {
      if (required[i].value == '') {
        required[i].classList.add('has-error-border')
        errors.push(required[i])
      } else {
        required[i].classList.remove('has-error-border')
      }
    }
  }
  if (errors.length) {
    showErrorFlash()
    return true
  }
}

const validatePhoneNumber = async () => {
  try {
    const phoneEl     = document.getElementById('patient_phone')
    const phone       = phoneEl.value
    const requestUrl  = `/patients/validate_phone_via_twilio?phone=${phone}`
    const request     = new FetchRequest('get', requestUrl)
    const response    = await request.perform()
    const data        = await response.json

    if (data.valid == false) {
      phoneEl.classList.add('has-error-border')
      alertErrorFlash(data.body)
      scrollToError()
      throw new Error(data.body)
    } else {
      phoneEl.classList.remove('has-error-border')
      return true
    }
  } catch (error) {
    console.error(error)
    return false
  }
}

const requireTextToOrderConsent = (orderOrigin) => {
  const textConsent = document.getElementById('patient_text_consent')
  const textConsentLabel = document.querySelector(`label[for=${textConsent.id}]`)

  if (orderOrigin.value.includes('_t2o_') && !textConsent.checked) {
    textConsentLabel.classList.add('border-all-2', 'has-error-border')
    return true
  } else {
    textConsentLabel.classList.remove('border-all-2', 'has-error-border')
    return false
  }
}

const renderSelectedPatientInfo = (patient) => {
  document.getElementById('selected_patient_name').innerText = `${patient.last_name}, ${patient.first_name}, ${patient.email}`
  document.getElementById('remove_selected_patient').classList.remove('hidden')
}

const toggleCartInfoChangeModal = () => {
  // uses jQuery 
  $('#cartInfoChangeModal').modal("show")
}

const toggleSpinnerModal = () => {
  // uses jQuery 
  const spinnerModal = document.getElementById('spinnerModal')
  if (isVisible(spinnerModal)) {
    $('#spinnerModal').modal("hide")
  } else {
    $('#spinnerModal').modal("show")
  }
}

const displayPaymentModal = () => {
  // uses jQuery 
  const paymentInfoModal = document.getElementById('paymentInfoModal')
  if (isVisible(paymentInfoModal)) {
    $('#paymentInfoModal').modal("hide")
  } else {
    $('#paymentInfoModal').modal("show")
  }
}

const appendPatientToShippingAddress = (patient) => {
  // uses patient obj keys to find class element input fields
  // appends patient info to shipping address form and patient info field
  if (orderOriginPresent()) {
    const selectedPatient = selectedPatientEl()
    selectedPatient.value = `${patient.first_name} ${patient.last_name}`
  }

  for (const [key, value] of Object.entries(patient)) {
    const inputEl = document.querySelectorAll(`.patient_${key}`)
    if (!isPresent(inputEl[0])) continue
    if (isPresent(inputEl[0].getElementsByTagName('input')[0])) {
      inputEl[0].getElementsByTagName('input')[0].value = value
    }
    if (isPresent(inputEl[0].getElementsByTagName('select')[0])) {
      inputEl[0].getElementsByTagName('select')[0].value = value
    }
  }
}

const selectedPatientEl = () => {
  if (isPresent(document.getElementById('order_patient_info'))) { // targets the order page
    return document.getElementById('order_patient_info')
  }
  if (isPresent(document.getElementById('selected_patient_name'))) { // targets patient management page
    return document.getElementById('selected_patient_name')
  }
}

const clearPatientForm = (including_email) => { // completely clears the patient and stripe form unless on order page
  const stripeCheckBox    = document.getElementById('patient_stripe_form')
  const patientInputs     = document.getElementsByClassName('patient-input')
  const paymentFormCard   = document.getElementById('payment-form-card')
  const emailMsgEl        = document.getElementById('email-msg')

  if (!orderOriginPresent()) { // hide stripe form and payment info on patient management page
    toggleOffElementById('toggle-stripe-form')
    toggleOffElementById('current-payment-method')
  }

  if (isPresent(stripeCheckBox)) { stripeCheckBox.checked = false } // uncheck the stripe checkbox
  if (isPresent(paymentFormCard)) { paymentFormCard.classList.remove('has-error-border') } // remove payment error border
  if (isPresent(emailMsgEl)) { emailMsgEl.innerText = '' }
  
  for (let i = 0; i < patientInputs.length; i++) { // iterate through & clear all info, values, & borders from patient form
    patientInputs[i].checked = false
    patientInputs[i].classList.remove('has-error-border')

    if (patientInputs[i].id == 'patient_email') { // re-enables email input
      if (including_email == true || patientInputs[i].disabled == false) {
        patientInputs[i].disabled = false
        patientInputs[i].classList.remove('form-control', 'disabled')
        patientInputs[i].value = ''
      }
    } else { 
      patientInputs[i].value = ''
    }

    if (patientInputs[i].id == 'patient_text_consent') {
      document.querySelector(`label[for='${patientInputs[i].id}']`).classList.remove('border-all-2', 'has-error-border')
    }

  }
  initializeStripeElements()
}

const closePatientForm = () => { // hides patient form
  const exitPatientFormBtn = document.getElementById('exit-create-patient-form')
  if (isPresent(exitPatientFormBtn)) { exitPatientFormBtn.click() }
}

const toggleOnElementById = (elementId) => {
  const formElement = document.getElementById(elementId)
  if (isPresent(formElement)) { formElement.classList.remove('hidden') }
}

const toggleOffElementById = (elementId) => {
  const formElement = document.getElementById(elementId)
  if (isPresent(formElement)) { formElement.classList.add('hidden') }
}

const orderOriginPresent = () => {
  return isPresent(document.getElementById('order_origin'))
}

const orderOriginIsSto = () => {
  if (!orderOriginPresent()) return false
  return document.getElementById('order_origin').value == 'wisvis_single_sto'
}
const orderOriginIsT2oStp = () => {
  if (!orderOriginPresent()) return false
  return document.getElementById('order_origin').value == 'wisvis_t2o_stp'
}

const orderOriginIsWtp = () => {
  if (!orderOriginPresent()) return false
  return document.getElementById('order_origin').value.includes('wtp')
}

const scrollToError = () => {
  const error = document.querySelector('.has-error-border')
  if (isPresent(error)) {
    error.scrollIntoView({block: 'center', behavior: 'smooth'})
  }
}

const showErrorFlash = () => {
  const error = document.querySelector('.has-error-border')
  let flashMsg = 'Missing required information.'
  if (!isPresent(error)) {
    alertErrorFlash(flashMsg)
  } else {
    if (error.required && error.type != 'checkbox') {
      flashMsg = 'Missing required information.'
    } else if (error.tagName == 'LABEL') {
      flashMsg = 'Text consent is required for Text2Order Orders.' 
    } else if (error.id == 'payment-form-card') {
      flashMsg = "Payment method missing." 
    }
  }
  alertErrorFlash(flashMsg)
}

const addErrorBorder = (element) => {
  element.classList.add('has-error-border')
}

const removeErrorBorder = (element) => {
  element.classList.remove('has-error-border')
}

const displayPatientCard = (patient, patientCard) => { // display current payment info if present on patient management page
  if (orderOriginPresent()) return 
  if (isPresent(patientCard)) { 
    toggleOnElementById('delete-payment-method')
    toggleOnElementById('current-payment-method')
    document.getElementById('card-brand').innerText     = patientCard.card_brand
    document.getElementById('card-last-four').innerText = patientCard.card_last_four
    document.getElementById('card-exp-date').innerText  = patientCard.card_exp_date
    document.getElementById('delete-payment-method').dataset.patientId = patient.id // delete card functionality
  } else {
    toggleOffElementById('delete-payment-method')
    toggleOffElementById('current-payment-method')
  }
}

const resetRequestType = () => { // reset request type on patient submit btn back to POST
  const patientSubmitBtn  = document.getElementById('patient-submit-btn')
  patientSubmitBtn.dataset.requestType = 'POST' 
  delete patientSubmitBtn.dataset.patientId // deletes data-patient-id attr
}

const resetTax = async () => {
  const retailTax = document.getElementById('wtp_retail_tax')
  if (!isPresent(retailTax)){ return }
  if (!orderOriginIsWtp() || retailTax.value == 'TBD') { return }
  const id = document.getElementById('address-id-holder').dataset.id
  const taxTooltip = document.getElementById('tax_tooltip')
  const calcTaxLink = document.querySelector('.calc-tax-link')
  const request = new FetchRequest('post', `/web_to_patient_orders/${id}/reset_tax`, { responseKind: 'json'})
  const response = await request.perform()
  const data = await response.json
  if (data.status == 200) {
    taxTooltip.classList.remove('hidden')
    retailTax.innerText = 'TBD'
    // Needs to reinitialize bootstrap tool in jquery or it gets defaulted look
    $('[data-toggle="tooltip"]').tooltip()
    calcTaxLink.classList.remove('hidden')
  }
}

const submitOrder = () => {
  const orderForm = document.getElementById('order_form')
  orderForm.submit()
  setTimeout( () => { 
    $("#loadingModal").modal("show");
  }, 400);  
}
