import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  showESModal() {
    const pid = event.target.dataset.id
    const massUpdate = document.getElementById('mass-update-submit').dataset.updateAll
    if (massUpdate == 'false') {
      $(`.es-product-modal-${pid}`).modal('show')
      event.preventDefault()
    }
  }

  confirmEsProductPrice() {
    const confirmUpdate = event.target.dataset.update
    const pid = event.target.dataset.pid
    const esProductForm = document.getElementById(`product_price_form_${pid}`)
    if (confirmUpdate == 'true') {
      Rails.fire(esProductForm, 'submit')
    }
  }

  initiateMassProductUpdate() {
    const massUpdate = document.querySelectorAll('.mass-update')
    if (confirm('Are you sure?')){
      if (massUpdate.length > 0 && this.esProductPriceSelected(massUpdate)){
        this.appendProductsToModal(massUpdate)
      }
      else{
        this.massUpdateProductPrice(massUpdate)
      }
    }
  }

  esProductPriceSelected(updateProducts) {
    let result = false
    updateProducts.forEach((el => {
      if (el.classList.contains('ES-price-update')){
        result = true
      }
    }))
    return result
  }
  
  appendProductsToModal(updateProducts) {
    updateProducts.forEach(el => {
      const updateProductList = document.getElementById('update_product_list')
      const itemDescription = el.closest('tr').querySelector('.first-price-row').innerText
      const updateItemPrice = `<tr><td>${itemDescription}</td><td></td></tr>`
      const esProdElement = `<tr><td>${itemDescription}</td><td class='text-center'><label for='es_prod_${el.dataset.id}'><input type='checkbox' class='mr-2' style='width: auto' aria-required='true' id='es_prod_${el.dataset.id}' product_id='${el.dataset.id}' checked></input></label></td></tr>`
      if (el.classList.contains('ES-price-update')){
        updateProductList.innerHTML += esProdElement
      }
      else{
        updateProductList.innerHTML += updateItemPrice
      }
    })
    this.showEsUpdateAllModal()
  }

  massUpdateProductPrice(updateProducts) {
    const priceForm = document.querySelectorAll('.price-form')
    if (updateProducts.length == 0){
      alertInfoFlash('No Price Change Detected!')
      return
    }
    updateProducts.forEach(el => {
      el.click()
    })

    setTimeout( () => {
      priceForm.forEach(el => { 
        el.querySelector("input[type=submit]").classList.remove("mass-update")
      })
    }, 1000)
  }

  showEsUpdateAllModal() {
    const massUpdateBtn = document.getElementById('mass-update-submit')
    massUpdateBtn.dataset.updateAll = 'true'
    $("#update_all_product_modal").modal('show')
  }

  confirmUpdateAll() {
    const esProductsInModal = document.getElementById('update_all_product_modal').querySelectorAll('input')
    this.removeUpdateClass(esProductsInModal)
    const massUpdate = document.querySelectorAll('.mass-update')
    $("#update_all_product_modal").modal('hide')
    this.massUpdateProductPrice(massUpdate)
  }

  removeUpdateClass(selectedEsProducts) {
    selectedEsProducts.forEach( el => {
      const update_btn = document.getElementById(`es-price-submit-${el.getAttribute('product_id')}`)
      if (!el.checked && update_btn.classList.contains('mass-update')) {
        update_btn.classList.remove('mass-update')
        this.getCurrentPrice(el.getAttribute('product_id')) 
      }
    })
  }

  async getCurrentPrice(pid) {
    const request = new FetchRequest('POST',  `/get_current_price?id=${pid}`, { responseKind: 'json'})
    const response = await request.perform()
    const data = await response.json
    if (response.ok) { 
      document.getElementById(`${data.id}`).value = data.price
      document.getElementById(`price_6month _${data.id}`).value = data.price_6month
      document.getElementById(`price_annual_${data.id}`).value = data.price_annual
    }
  }
}