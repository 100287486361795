import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "button" ]

  disableWith(event) {
    event.preventDefault();
    
    let originalText = this.buttonTarget.textContent;
    this.buttonTarget.setAttribute('data-original-text', originalText);

    let replacementText = this.buttonTarget.dataset.disableWith;
    this.buttonTarget.textContent = replacementText;

    this.buttonTarget.disabled = true;
  }

  enable() {
    let originalText = this.buttonTarget.getAttribute('data-original-text');
    this.buttonTarget.textContent = originalText;
    this.buttonTarget.disabled = false;
  }
}
