import { Controller } from "@hotwired/stimulus"
import { initializeStripeElements } from '../packs/stripe.js'
import { removeErrorBorder, toggleOnElementById, toggleOffElementById } from '../concerns/patient_shared.js'

export default class extends Controller {

  displayPatientForm() {
    toggleOnElementById('patient-form')
    toggleOffElementById('order_form')
    toggleOffElementById('order-cart')
    initializeStripeElements()
  }

  hidePatientForm() {
    toggleOffElementById('patient-form')
    toggleOnElementById('order_form')
    toggleOnElementById('order-cart')
  }

  removeTextConsentErrorBorder() {
    const inputLabel = document.querySelector(`label[for='${this.element.id}']`)
    inputLabel.classList.remove('border-all-2', 'has-error-border')
  }

  enableTextToOrderForStp() {
    const enableTextToOrder = document.getElementById('order_enable_text2order')
    const orderOrigin = document.getElementById('order_origin')
    const orderId = document.getElementById('address-id-holder').dataset.id
    enableTextToOrder.value = this.element.checked

    if (this.element.checked) {
      orderOrigin.value = 'wisvis_t2o_stp'
      toggleOnElementById('stp-t2o-alert')
      toggleOnElementById('patient-form')
      toggleOnElementById('stp-patient-section')
      toggleOnElementById('patient-text-asterisk')
      toggleOffElementById('order_form')
      toggleOffElementById('order-cart')
    } else {
      orderOrigin.value = 'wisvis_single_stp'
      toggleOffElementById('stp-t2o-alert')
      toggleOffElementById('stp-patient-section')
      toggleOffElementById('patient-form')
      toggleOffElementById('patient-text-asterisk')
      toggleOnElementById('order_form')
      toggleOnElementById('order-cart')
      removeErrorBorder(document.getElementById('selected_patient_name').closest('div.card'))
    }
  }
}