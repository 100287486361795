import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'
import { addErrorBorder, removeErrorBorder, toggleOffElementById, toggleOnElementById, toggleSpinnerModal } from "../concerns/patient_shared.js"

export default class extends Controller {

  toggleRecentStatementsCard() {
    const chevron = this.element.querySelector('.statements-chevron')
    if (isVisible(this.element.nextElementSibling)) {
      chevron.classList.remove('fa-chevron-down')
      chevron.classList.add('fa-chevron-up')
      this.hideStatements()
    } else {
      chevron.classList.remove('fa-chevron-up')
      chevron.classList.add('fa-chevron-down')
      this.displayStatements()
    }
  }

  displayStatements() {
    this.element.nextElementSibling.classList.remove('hidden')
  }

  hideStatements() {
    this.element.nextElementSibling.classList.add('hidden')
  }

  batchedZipDownload() {
    if (this.element.dataset.hasFilepath == 'false') {
      alertErrorFlash('No available statements to download')
      return
    }
    const pastStatementsForm =  document.getElementById('past-statements-form')
    const accounts = pastStatementsForm.querySelector('input[name=accounts]').value
    const year = pastStatementsForm.querySelector('input[name=year]').value
    const month = pastStatementsForm.querySelector('input[name=month]').value
    if (!isPresent(year) || !isPresent(month)) {
      alertErrorFlash('Select year and month')
      return
    }
    this.batched_zip_download({ accounts: accounts, year: year, month: month })
  }

  batched_zip_download(data) {
    toggleSpinnerModal()
    const requestUrl = `/billing/online_statements/batched_zip_download?accounts=${data.accounts}&year=${data.year}&month=${data.month}`
    window.location.href = requestUrl
    toggleSpinnerModal()
  }

  viewStatements() {
    const year = document.querySelector('.statement-year-select').value
    const month = document.querySelector('.statement-month-select').value
    if (!isPresent(year) || !isPresent(month)) {
      alertErrorFlash('Select year and month')
      return
    }
    this.getSelectedYearMonthStatement(this.element.dataset.accountsArray, year, month)
  }

   getSelectedYearMonthStatement(accountsArray, year, month) {
    const pastStatementsForm =  document.getElementById('past-statements-form')
    pastStatementsForm.querySelector('input[name=accounts]').value = accountsArray
    pastStatementsForm.querySelector('input[name=year]').value = year
    pastStatementsForm.querySelector('input[name=month]').value = month
    pastStatementsForm.submit()
   }

  enablePastStatements() {
    document.querySelector('.statement-year-select').disabled = false
    document.querySelector('.statement-month-select').disabled = false
    document.querySelector('.view-statements-btn').classList.remove('disabled')
  }

  disablePastStatements() {
    const yearSelectEl = document.querySelector('.statement-year-select')
    const monthSelectEl = document.querySelector('.statement-month-select')
    document.querySelector('.view-statements-btn').classList.add('disabled')
    yearSelectEl.disabled = true
    monthSelectEl.disabled = true
    yearSelectEl.value = ''
    monthSelectEl.value = ''
  }

  findAccountSelect() {
    this.emptyAvailableStatements()

    let accountsArray = []
    const accounts = this.element.options
    for (let i = 0; i < accounts.length; i++) {
      if (accounts[i].selected) { accountsArray.push(accounts[i].value) }
    }
    if (accountsArray.length) {
      this.enablePastStatements()
      document.querySelector('.view-statements-btn').dataset.accountsArray = accountsArray
    } else {
      this.disablePastStatements()
      this.unCheckSelectAll()
    }
    this.toggleRecentStatements(accountsArray)
  }

  unCheckSelectAll() {
    document.getElementById('select-all-accounts').checked = false
  }

  toggleRecentStatements(accountsArray) {
    this.hideAllRecentStatements()
    for (let i = 0; i < accountsArray.length; i++) {
      const recentStatementEl = document.querySelectorAll(`.statements-${accountsArray[i]}`)
      if (recentStatementEl.length) {
        for (let r = 0; r < recentStatementEl.length; r++) {
          recentStatementEl[r].classList.remove('hidden')
        }
        this.toggleNoRecentStatementText('hide')
      } 
    }
  }

  emptyAvailableStatements() {
    const availableStatementsEl = document.getElementById('available-statements-body')
    if (isPresent(availableStatementsEl)) { 
      availableStatementsEl.innerHTML = ''
    } 
  }

  hideAllRecentStatements() {
    if (!isPresent(document.getElementById('recent-statements-body'))) return
    const recentStatementEl = document.querySelectorAll('.statements-hide')
    if (recentStatementEl.length) {
      for (let i = 0; i < recentStatementEl.length; i++) {
        recentStatementEl[i].classList.add('hidden')
      }
    }
    this.toggleNoRecentStatementText('show')
  }

  toggleNoRecentStatementText(display) {
    const textEl = document.getElementById('no-available-statements')
    if (!isPresent(textEl)) return
    if (display == 'show') {
      textEl.classList.remove('hidden')
    } else {
      textEl.classList.add('hidden')
    }
  }

  selectAllAccounts() {
    let accountsIdArray = []
    const accountsSelectEl = document.getElementById('accounts-select')
    if (this.element.checked) {
      for (let i = 0; i < accountsSelectEl.options.length; i++) { 
        accountsSelectEl.options[i].selected = true
        accountsIdArray.push(accountsSelectEl.options[i].value)
      } 
      accountsSelectEl.tomselect.setValue(accountsIdArray)
    } else {
      accountsSelectEl.tomselect.clear()
    }
  }

  statementsToggle() {
    if (document.getElementById('statements-toggle').checked) {
      document.getElementById('statements-off').style = 'color: unset !important;'
    } else {
      document.getElementById('statements-off').style = 'color: #dc3444 !important;'
    }

    const request = new FetchRequest('PATCH', `/users/toggle_online_statement`)
    const response = request.perform()
    if (response.ok) { console.error('ok') }
  }

}