import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'
import { initializeStripeElements } from '../packs/stripe.js'
import {
  addErrorBorder,
  appendPatientToShippingAddress,
  clearPatientForm,
  clearShippingAddressForm,
  closePatientForm,
  displayPatientCard,
  displayPaymentModal,
  hasEmptyRequiredFields,
  validatePhoneNumber,
  orderOriginPresent,
  removeErrorBorder,
  resetRequestType,
  toggleOffElementById, 
  toggleOnElementById,
  toggleSpinnerModal,
  updateOrderFormWithPatient,
  renderSelectedPatientInfo,
} from '../concerns/patient_shared.js'

export default class extends Controller {

  connect() {
    this.uncheckStripeCheckbox()
    this.bindEnableDisableButtonEvent()
  }

  uncheckStripeCheckbox() {
    const stripeCheckBox = document.getElementById('patient_stripe_form')
    if (isPresent(stripeCheckBox)) { stripeCheckBox.checked = false }
  }

  async hitPatientApiEndpoint(patient) {
    toggleSpinnerModal()
    const patientSubmit = document.getElementById('patient-submit-btn')
    let requestUrl = '/patients/'
    let requestType = patientSubmit.dataset.requestType
    if (requestType == 'PATCH') {
      requestUrl = requestUrl + patientSubmit.dataset.patientId
    }

    const request = new FetchRequest(requestType, requestUrl, {
      body: { patient },
      responseKind: "json"
    })
    const response = await request.perform()
    const data = await response.json
    toggleSpinnerModal()

    if (response.ok) { // patient created/updated successfully (function call order is important)
      closePatientForm()
      toggleOffElementById('toggle-stripe-form')

      // could probably have an if origin is present and if not (order vs manage patient)
      if (orderOriginPresent()) {
        clearShippingAddressForm()
        updateOrderFormWithPatient(data.patient)
        appendPatientToShippingAddress(data.patient)
      } else {
        displayPatientCard(data.patient, data.patient_card)
        this.disablePatientEmail()
        this.updateRequestTypeAndPatient(requestType, data.patient.id, patientSubmit)
        renderSelectedPatientInfo(data.patient)
      }
      alertInfoFlash(this.flashMsg(requestType))
      this.resetConsentModal()

    } else { // patient created/updated un-successfully
      // if (requestType == 'PATCH') { resetRequestType() }
      alertErrorFlash(data.errors)
    }
    // if (orderOriginPresent()) { clearPatientForm() }
  }

  bindEnableDisableButtonEvent() {
    const patientStripeBtn = document.getElementById('patient-stripe-btn')
    patientStripeBtn.addEventListener('click', () => {
      patientStripeBtn.disabled = true
      setTimeout(() => {
        patientStripeBtn.disabled = false
      }, 2000)
    })
  }

  flashMsg(requestType = null) {
    if (orderOriginPresent())   { return 'Patient successfully selected for this order.' }
    if (requestType == 'PATCH') { return 'Patient successfully updated.' }
    return 'Patient successfully created.'
  }

  createPatient() {
    this.toggleOffStripeFormIfDemoAccount()
    let patient = this.getPatientInfo()
    if (hasEmptyRequiredFields()) { return }
    validatePhoneNumber()
      .then((isValidPhoneNumber) => {
        if (isValidPhoneNumber) {
          console.log('phone is valid')
          this.hitPatientApiEndpoint(patient)
        } else {
          console.log('phone is invalid')
        }
      })
    if (isVisible(document.getElementById('toggle-stripe-form'))) { patient['patient_stripe_token'] = this.getStripeToken() }
    if (!this.consentChecked() && this.consentNotConfirmed()) {
      $('#noConsentsConfirmationModal').modal('show')
      return
    }
  }

  toggleOffStripeFormIfDemoAccount() {
    if (document.getElementById('patient-stripe-btn').dataset.isDemoCc == 'true') { 
      this.uncheckStripeCheckbox()
      this.toggleStripeForm() 
    }
  }

  consentChecked() {
    return document.getElementById('patient_text_consent').checked || document.getElementById('patient_email_consent').checked
  }

  consentNotConfirmed() {
    return document.getElementById('consent_confirm_btn').getAttribute('confirm_consent') == 'false'
  }

  confirmPatientConsent() {
    document.getElementById('consent_confirm_btn').setAttribute('confirm_consent', 'true')
    $('#noConsentsConfirmationModal').modal('hide')
    this.createPatient()
  }

  resetConsentModal() {
    document.getElementById('consent_confirm_btn').setAttribute('confirm_consent', 'false')
  }

  updateRequestTypeAndPatient(requestType, patientId, patientSubmit) {
    if (requestType == 'POST') { 
      patientSubmit.dataset.requestType = 'PATCH'
      patientSubmit.dataset.patientId = patientId
    }
  }

  toggleStripeForm() {
    const stripeForm = document.getElementById('toggle-stripe-form')
    if (isVisible(stripeForm)) {
      toggleOffElementById('toggle-stripe-form')
    } else { 
      toggleOnElementById('toggle-stripe-form')
    }
  }

  togglePaymentInfoModal(){
    displayPaymentModal()
  }

  disablePatientEmail(){
    const emailInput = document.getElementById('patient_email')
    emailInput.disabled = true
    emailInput.classList.add('form-control', 'disabled')
  }

  getPatientInfo() { // init and return hash with patient input element id as key, and patient input element value as value
    let results = {}
    const patientInputs = document.getElementsByClassName('patient-input')
    for (let i = 0; i < patientInputs.length; i++) {
      if (patientInputs[i].type == 'checkbox') {
        results[patientInputs[i].id] = patientInputs[i].checked
      } else {
        results[patientInputs[i].id] = patientInputs[i].value
      }
    }
    return results
  }

  getStripeToken() { // get stripe token from hidden payment form element value
    const hiddenPaymentForm = document.getElementById('hidden-payment-form')
    if (!isPresent(hiddenPaymentForm)) return
    return hiddenPaymentForm.value
  }

  clearPatientFormHelper() {
    clearPatientForm(true)
    resetRequestType()
  }

  validateEmailInput() {
    if (!isPresent(this.element.value.trim())) {
      this.enableDisablePatientSubmitBtn(false)
      document.getElementById('email-msg').innerText = ''
      removeErrorBorder(this.element)
      return
    }
    this.validatePatientEmail(this.element)
  }

  enableDisablePatientSubmitBtn(bool) {
    document.getElementById('patient-stripe-btn').disabled = bool
  }

  async validatePatientEmail(input_el) {
    let requestUrl = '/validate_patient_email'
    let requestType = 'POST'
    const request = new FetchRequest(requestType, requestUrl, {
      body: { email: input_el.value },
      responseKind: "json"
    })
    const response = await request.perform()
    const data = await response.json
    const emailMsgEl = document.getElementById('email-msg')
    if (response.ok) {
      emailMsgEl.innerText = ''
      removeErrorBorder(input_el)
      this.enableDisablePatientSubmitBtn(false)
    } else {
      emailMsgEl.innerText = data.message
      addErrorBorder(input_el)
      this.enableDisablePatientSubmitBtn(true)
    }
  }

}