import { addErrorBorder, removeErrorBorder, scrollToError, orderOriginIsWtp } from './patient_shared.js'

export {
  checkProductForm,
  hasErrorBorder,
  promoProductEligible,
  promoNotShown
}

const checkProductForm = (clk=false) => {
  let errorMsg;
  let eye;
  const gridOptionLabel = document.getElementById('_toggle-grid-label')
  if (isPresent(gridOptionLabel) && gridOrdersInvalidQty()) {
    event.preventDefault();
    errorMsg = "Click Grid Order to toggle off if no grid product(s) is desired.";
    alertErrorFlash(errorMsg);
    scrollToThis('#_toggle-grid-label');
    return true;
  };

  if (noProductsSelected()) {
    event.preventDefault();
    errorMsg = "Please select a product to add ";
    alertErrorFlash(errorMsg);
    scrollToThis('#right-eye-wrap');
    return true;
  }

  // Function call to check for left, right, and other eye product to validate if missing parameters on product //

  if (eye = productParamMissing(clk)) {
    event.preventDefault();
    if (eye.length > 1) {
      errorMsg = 'Please fill out parameters for all products';
    } else {
      errorMsg = `Please fill out parameters for the ${eye[0]} eye product`;
    };
    alertErrorFlash(errorMsg);
    scrollToError()
    return true;
  }

  // Passing true as quanity flag arg to same function call since variable declaration are the same as above but just needed to check quantity value instead //

  if (eye = productParamMissing(clk, true)) {
    event.preventDefault()
    if (eye.length > 1) {
      errorMsg = 'Please fill out quantity for all products'
    } else {
      errorMsg = `Please fill out quantity for the ${eye[0]} eye product`
    }
    alertErrorFlash(errorMsg)
    scrollToError()
    return true;
  }
  return false;
  // showVspModal()
  
  // setTimeout( () => { 
  //   if (!hasErrorBorder() && hidePopUp() ) {
  //     $("#loadingModal").modal("show");
  //   };
  // }, 400);
}

// const showVspModal = () => {
//   if (hasErrorBorder()) {
//     event.preventDefault();
//     return;
//   }
//   const eyes = ['right', 'left'];
//   const isProdIdInCart = [];
//   eyes.forEach(eye => {
//     const productDropdown = document.getElementById(`${eye}-eye-product-dropdown`);
//     const productID = productDropdown.options[productDropdown.selectedIndex].value
//     if (productID == 'SP00005') {
//       isProdIdInCart.push('true');
//     }
//     else {
//       isProdIdInCart.push('false');
//     };
//   });
//   if (isProdIdInCart.includes('true')){
//     // uses jQuery 
//     $('#vsp_modal').modal("show");
//     event.preventDefault();
//   }
// }

// const hidePopUp = () => {
//   const eyes = ['right', 'left']
//   for( let eye of eyes) {
//     if(getSelectedOption(`${eye}-eye-product-dropdown`).value =='SP00005'){
//       return false
//     }
//   }
//   return true
// }

const getSelectedOption = (selector) => {
  const productDropdown = document.getElementById(selector)
  if(!isPresent(productDropdown)) return
  return productDropdown.options[productDropdown.selectedIndex]
}

const productParamMissing = (clk, quantityCheck=false) => {
  const eyes = orderOriginIsWtp() || clk === 'true' ? ['right', 'left'] : ['right', 'left', 'others']
  let eyeMissing = []
  for (let eye of eyes) {
    const eyeQuantity = document.getElementById(`${eye}-eye-quantity`)
    const headingKey = document.getElementById(`${eye}-heading-key`).dataset.value
    const paramLength = document.querySelector(`#${eye}-eye-options`).querySelectorAll('.params').length
    const selectedProduct = getSelectedOption((`${eye}-eye-product-dropdown`))
    if (isPresent(selectedProduct) && selectedProduct.value != "") {
      if (quantityCheck) {
        if (eyeQuantity.value == "") {
          addErrorBorder(eyeQuantity)
          eyeMissing.push(eye)
        } else {
          removeErrorBorder(eyeQuantity)
        }
      } else if (headingKey != '00' && !checkParams(paramLength, eye)) {
        eyeMissing.push(eye)
      }
    }
    
  }

  // Returns array of right, left, or other eye(s) to determine if quantity or parameters are invalid and which eye //

  if (eyeMissing.length > 0) return eyeMissing
}

const hasErrorBorder = () => {
  return document.querySelectorAll(".has-error-border").length > 0;
}
const gridOrdersInvalidQty = () => {
  return gridSelected() && noGridQuantity()
}
  
const gridSelected = () => {
  return document.querySelector('.toggle-grid').checked
}

const noProductsSelected = () => {
  const dropdownOptions = ['grid', 'right', 'left', 'others']
  const dropdownNotSelected = []
  for( let option of dropdownOptions) {
    const eye = getSelectedOption(`${option}-eye-product-dropdown`)
    if (!isPresent(eye)) continue
    if( eye.value == '' || eye.text.length == 0){
      dropdownNotSelected.push(true)
    } else {
      dropdownNotSelected.push(false)
    }
  }
  if(!dropdownNotSelected.includes(false)) return true
}

const noGridQuantity = (gridQty = false) => {
  let qtyArr = [];
  const qtyThreshold = 99;
  let qty = '';
  const gridQuantity = document.querySelectorAll('.grid-quantity')
  Array.from(gridQuantity).forEach((el) => {
    qty = el.value.replace(/\D/g,'');
    qtyArr.push(qty);
    removeErrorBorder(el);
    if ((parseInt(qty) > qtyThreshold) || (parseInt(qty)) <= 0) {
      addErrorBorder(el) 
    }
  })
  if (hasErrorBorder() || _.compact(qtyArr).length == 0) {
    gridQty = true 
  }
  return gridQty
}

const promoProductEligible = () => {
  const rightProductId = getProductId('right')
  const leftProductId = getProductId('left')
  const promoArray = ['SP00005']
  return promoArray.includes(rightProductId) || promoArray.includes(leftProductId)
}

const promoNotShown = (btn) => {
  return btn.dataset.promo == 'false'
}

const getProductId = (eye) => {
  const dropdown = document.getElementById(`${eye}-eye-product-dropdown`)
  const productId = dropdown.options[dropdown.selectedIndex].value
  return productId
}