import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'
import { initializeStripeElements } from '../packs/stripe.js'
import {
  appendPatientToShippingAddress,
  checkForRequiredPatientParams,
  clearPatientForm,
  clearShippingAddressForm,
  displayPatientCard,
  hasEmptyRequiredFields,
  orderOriginPresent,
  removeErrorBorder,
  resetRequestType,
  toggleOffElementById,
  toggleOnElementById,
  toggleSpinnerModal,
  updateOrderFormWithPatient,
  scrollToError,
  showErrorFlash,
  renderSelectedPatientInfo,
  getAndSelectPatient,
  resetTax
} from '../concerns/patient_shared.js'


export default class extends Controller {

  connect() {
    const cardElement = document.getElementById('card-element')
    if (isPresent(cardElement)) { initializeStripeElements() } // initialize stripe for patient management page
  }

  selectPatient() {
    const patientId = this.element.dataset.value
    const toggleOrderTypePatientCheck = document.getElementById('toggle_ordertype_patient_check')
    if (isPresent(toggleOrderTypePatientCheck)) {
      toggleOrderTypePatientCheck.dataset.value = patientId
    }
    this.getAndSelectPatient(patientId, event)
  }

  async getAndSelectPatient(patientId, event) {
    toggleSpinnerModal()
    let orderOrigin = orderOriginPresent() ? document.getElementById('order_origin').value : ''
    const request   = new FetchRequest("get", `/get_patient?id=${patientId}&origin=${orderOrigin}`, { responseKind: 'json' })
    const response  = await request.perform()
    const data      = await response.json

    if (response.ok) { // check for presence of dob, stripe_customer_id, t2o consent(conditional) with data.update_required
      toggleSpinnerModal()
      if (data.t2o_maxed) { // patient is maxed on t2o's
        alertErrorFlash("That patient's phone number already has the max alloted Text2Orders")
        clearShippingAddressForm()
        event.preventDefault()
        return
      }
      this.clearSearchTable()
      this.removePatientFromForm() // removes existing patient from form
      this.resetViewAllPatientsBtn()
      renderSelectedPatientInfo(data.patient) // append patient to selected patient element
      if (data.update_required) { // patient update(s) required
        this.updatePatient(data.patient, data.patient_card)
      } else { // patient update(s) NOT required
        this.selectPatientForOrderForm(data.patient)
      }
      displayPatientCard(data.patient, data.patient_card)
      removeErrorBorder(document.getElementById('selected_patient_name').closest('div.card'))
    } else {
      toggleSpinnerModal()
      alertErrorFlash(data.errors)
    }
  }

  updatePatient(patient, patientCard) {
    this.modifyRequestType(patient) // modifies patient form submit request to PATCH instead of POST
    this.appendPatientToPatientForm(patient)
    hasEmptyRequiredFields()
    if (orderOriginPresent()) { // on order page
      document.getElementById('create-patient-btn').click() // click activates different stripe controller 'patient-order-form#displayPatientForm'
      this.checkForPaymentMethod(patientCard)
      this.addErrorBorderToPaymentCard(patient)
    } else { // patient update(s) required & on Patient Management page
      initializeStripeElements()
    }
    scrollToError()
    showErrorFlash()
    // alertErrorFlash('Missing required information.') 

  }

  selectPatientForOrderForm(patient, patientCard) {
    if (orderOriginPresent()) { // on order page
      clearShippingAddressForm()
      updateOrderFormWithPatient(patient)
      appendPatientToShippingAddress(patient)
      resetTax()
      toggleOffElementById('patient-form')
      toggleOnElementById('order_form')
      toggleOnElementById('order-cart')

      alertInfoFlash('Patient successfully selected for this order.')
    } else { // patient update(s) NOT required & on Patient Management page
      this.modifyRequestType(patient)
      renderSelectedPatientInfo(patient)
      this.appendPatientToPatientForm(patient)
      displayPatientCard(patient, patientCard)
    }
  }

  checkForPaymentMethod(patientCard) { // hide stripe form if patient has existing payment method (should only be used on order form)
    if (isPresent(patientCard)) { toggleOffElementById('toggle-stripe-form') }
  }

  async deletePatientCard() { // delete payment method for this patient
    if (!confirm('Performing this action will delete the existing card.')) return
    const request   = new FetchRequest("get", `/delete_card?id=${this.element.dataset.patientId}`, { responseKind: 'json' })
    const response  = await request.perform()
    if (response.ok) {
      displayPatientCard(null, null)
      alertInfoFlash('Payment method successfully deleted.')
    } else {
      alertErrorFlash('Error occurred while deleting payment method.')
    }
  }

  addErrorBorderToPaymentCard(patient) { // add red error border to payment card 
    if (!isPresent(patient.stripe_customer_id)) { 
      document.getElementById('payment-form-card').classList.add('has-error-border') 
    }
  }

  clearSearch() {
    const patientTerm = document.getElementById('patient_term')
    patientTerm.value = ''
  }

  toggleViewAllPatients() {
    if (document.getElementById('view-all-patients-icon').classList.contains('fa-chevron-up')) { return this.resetViewAllPatientsBtn() }
    this.setViewAllPatientsBtn()
  }

  setViewAllPatientsBtn() {
    document.getElementById('view-all-patients-btn').style = "pointer-events:none;" // RE-ENABLES FROM LINE 2 OF 'view_all_patients.coffee'
    document.getElementById('view-all-patients-btn').href = '/view_all_patients'
    document.getElementById('view-all-patients-text').textContent = 'Hide All Patient Accounts'
    document.getElementById('view-all-patients-icon').classList.remove('fa-chevron-down')
    document.getElementById('view-all-patients-icon').classList.add('fa-chevron-up')
  }

  resetViewAllPatientsBtn() {
    document.getElementById('view-all-patients-btn').href = ''
    document.getElementById('view-all-patients-text').textContent = 'View All Patient Accounts'
    document.getElementById('view-all-patients-icon').classList.remove('fa-chevron-up')
    document.getElementById('view-all-patients-icon').classList.add('fa-chevron-down')
    this.clearSearchTable()
  }

  clearSearchTable() {
    const patientTable = document.getElementById('search_patient_table')
    while(patientTable.firstChild) patientTable.removeChild(patientTable.firstChild)
  }

  modifyRequestType(patient) {
    const patientSubmitBtn = document.getElementById('patient-submit-btn')
    patientSubmitBtn.dataset.requestType = 'PATCH'
    patientSubmitBtn.dataset.patientId = patient.id
  }

  appendPatientToPatientForm(patient) {
    // uses patient obj keys to find class element input fields
    // appends patient info to patient form
    // const patientForm = document.getElementById('patient-form')
    // document.getElementById('order_patient_info').value = `${patient.first_name} ${patient.last_name}`
    for (const [key, value] of Object.entries(patient)) {
      const inputEl = document.getElementById(`patient_${key}`)
      if (!isPresent(inputEl)) continue
      if (inputEl.tagName == 'INPUT') { 
        inputEl.value = value 
        inputEl.checked = value 
      }
      if (key == 'email') { // disables email input
        inputEl.disabled = true
        inputEl.classList.add('form-control', 'disabled')
      }
      if (inputEl.tagName == 'SELECT') {
        if (patient.dob != null && patient.dob != "") {
          if (key == 'date_of_birth_month') { inputEl.value = new Date(patient.dob).getMonth()+1 }
          if (key == 'date_of_birth_day')   { inputEl.value = new Date(patient.dob).getDate() }
          if (key == 'date_of_birth_year')  { inputEl.value = String(new Date(patient.dob).getFullYear()) }
        }
        if (key == 'state') { inputEl.value = patient.state }
      } 
    }
  }

  removePatientFromForm() {
    if (orderOriginPresent()) { 
      toggleOnElementById('toggle-stripe-form')
      document.getElementById('order_patient_id').value = '' 
      this.deletePatientFromOrder()
    } // display stripe form on order page

    toggleOffElementById('remove_selected_patient')
    document.getElementById('enable-disable-address').click()
    document.getElementById('selected_patient_name').innerHTML= "<span>No patient account selected</span>"
    
    clearPatientForm(true)
    clearShippingAddressForm()
    resetRequestType()
  }

// this async function needs testing
  async deletePatientFromOrder() {
    const orderIdEl = document.getElementById('address-id-holder')
    if (!isPresent(orderIdEl)) return // return if no order id is present
    const orderId = orderIdEl.dataset.id
    const request = new FetchRequest('post', '/delete_patient_from_order', {
      body: { id: orderId },
      responseKind: 'json'
    })
    const response = await request.perform()
    const data = await response.json
    if (response.ok) {
      // alertInfoFlash('Patient removed.')
    } else {
      alertInfoFlash('An error has occurred.')
    }
  }
  
}