import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static values = {
    banner: Number
  }
  triggerCsvUpload() {
    const whiteListFile = document.getElementById('white-list-csv')
    const blackListFile = document.getElementById('black-list-csv')
    if (this.whiteListUpload()) { return whiteListFile.click() }
    if (this.blackListUpload()) { return blackListFile.click() }
  }

  get uploadTriggerType() {
    return event.target.id
  }

  get uploadFileName() {
    return event.target.files[0].name
  }

  displayCsvFileName() {
    const displayBlacklistFile = document.getElementById('display-black-list-csv')
    const displayWhitelistFile = document.getElementById('display-white-list-csv')
    if (this.whiteListUpload()) {
      displayWhitelistFile.innerHTML = ""
      displayWhitelistFile.innerHTML = `<b>File Name</b>: ${this.uploadFileName}`
    }
    if (this.blackListUpload()) {
      displayBlacklistFile.innerHTML = ""
      displayBlacklistFile.innerHTML = `<b>File Name</b>: ${this.uploadFileName}`
    }
  }

  blackListUpload() {
    return this.uploadTriggerType == 'black-list-upload-csv' || this.uploadTriggerType == 'black-list-csv'
  }

  whiteListUpload() {
    return this.uploadTriggerType == 'white-list-upload-csv' || this.uploadTriggerType == 'white-list-csv'
  }

  //Banner image library logic

  createBannerInstance() {
    const bannerForm = document.getElementById('new_banner')
    const bannerOrigin = document.getElementById('banner_origin')
    const bannerUploadView = document.getElementById('banner_view')
    const editBanner = this.element.dataset.edit
    const bannerId = this.element.dataset.bannerId
    bannerOrigin.value = 'upload'
    bannerUploadView.value = this.element.dataset.view
    if ( editBanner == 'true' ) {
      location.href = `/banner_assets?origin=${bannerOrigin.value}&banner_id=${bannerId}&selection=${editBanner}&view=${bannerUploadView.value}`
    } else {
      bannerForm.submit()
      document.getElementById('desktop-banner-upload-btn').classList.add('disabled')
      document.getElementById('mobile-banner-upload-btn').classList.add('disabled')
    }
  }


  toggleBannerSelection() {
    const selectedBanner = document.querySelector('.add-border-to-image')
    const currentBanner = event.target.closest('.msg-border')
    const deleteBtn = document.getElementById('delete-banner-image')
    const selectBtn = document.getElementById('select_banner_image')
    if (isPresent(selectedBanner)) {
      this.toggleOnOffSelectedBanner(selectedBanner, currentBanner, deleteBtn, selectBtn)
    } else {
      event.target.classList.add('selected')
      currentBanner.classList.add('add-border-to-image')
      this.toggleBtn(deleteBtn)
      this.toggleBtn(selectBtn)
    }
  }

  toggleOnOffSelectedBanner(selectedBanner, currentBanner, deleteBtn, selectBtn) {
    if (currentBanner.classList.contains('add-border-to-image')) {
      currentBanner.classList.remove('add-border-to-image')
      event.target.classList.remove('selected')
      this.toggleBtn(deleteBtn)
      this.toggleBtn(selectBtn)
    } else {
      selectedBanner.classList.remove('add-border-to-image')
      currentBanner.classList.add('add-border-to-image')
    }    
  }

  toggleBtn(el) {
    if (isPresent(el)) {
      if (el.classList.contains('disabled')) {
        el.classList.remove('disabled')
      } else {
        el.classList.add('disabled')
      }
    }
  }

  selectBanner() {
    const bannerOrigin = this.element.dataset.origin
    const bannerAssetId = document.querySelector('.add-border-to-image').querySelector('.banner-select').dataset.assetId
    const bannerId = this.element.dataset.bannerId
    const displayView = this.element.dataset.view
    this.selectAssetToBanner(bannerAssetId, bannerId, bannerOrigin, displayView)
  }

  async selectAssetToBanner(bannerAssetId, bannerId, bannerOrigin, displayView) {
    const request = new FetchRequest('patch', `/banner_assets/select_banner`, {
      body: { banner_id: bannerId,
              origin: bannerOrigin,
              id: bannerAssetId,
              view: displayView
            }, 
      responseKind: 'json'
    })
    const response = await request.perform()
    const data = await response.json
    if (data.success) {
      location.href = `/banners/${bannerId}/edit`
    } else {
      alertErrorFlash('Sorry, an error occurred. Please try again.')
    }
  }

  async deleteSelectedBannerAsset() {
    const assetId = document.querySelector('.selected').dataset.assetId
    const request = new FetchRequest('delete', `/banner_assets/delete_selected?id=${assetId}`,{responseKind: 'json'})
    const response = await request.perform()
    const data = await response.json
    if (data.success) {
      document.querySelector('.add-border-to-image').remove()
      document.getElementById('delete-banner-image').classList.add('disabled')
      alertSuccessFlash('Banner image successfully deleted!')
    } else if ( data.use) {
      alertErrorFlash('Sorry, this image/video is currently selected in for another banner.')
    } else {
      alertErrorFlash('Sorry, an error occurred. Banner image was not deleted.')
    }
  }

  orderConfirmationDefault() {
    const defaultPlacement = document.getElementById('banner_ad_page')
    const defaultLocation = document.getElementById('banner_ad_location')
    const locationOption = defaultLocation.appendChild(document.createElement('option'))
    if (defaultPlacement.value == 'order_confirmation') {
      locationOption.innerHTML = 'Bottom of page'
      locationOption.setAttribute('value', 'bottom_of_page')
    } else {
      locationOption.innerHTML = 'Top of page'
      locationOption.setAttribute('value', 'top_of_page')
    }
    locationOption.setAttribute('selected', 'selected')
    defaultLocation.removeChild(defaultLocation.children[0])
  }

  // stimulus function to request updating banner click count
  async updateBannerClick() {
    try {
      const request = new FetchRequest('patch',
         '/update_click_count/', {
          body: {
            id: this.bannerValue
          },
          responseKind: 'json'
       })
      const response = await request.perform()
      const data = await response.json
      if (data.status == 'Success') {
        console.log('Click Count Updated!')
      } else {
        console.error('Click Count Update Fail.')
      }
    } catch (error) {
      console.error('Error updating click count: ', error)
    }
  }

}