export { initializeStripeElements }

import { 
  hasEmptyRequiredFields,
  orderOriginPresent,
 } from "../concerns/patient_shared.js"

const initializeStripeElements = () => {
  let stripe = ''
  const cardElement = document.getElementById('card-element')
  const style = {
    base: {
      fontSize: '16px',
      color: '#32325d',
      fontSmoothing: 'antialiased'
    }
  }

  removeHiddenPaymentFormIfAny()
  if (document.getElementById('stripe-env').dataset.value == 'live') {
    stripe = Stripe('pk_live_aesL8fl7mw21r6PhhaHwP9uA')
  } else {
    stripe = Stripe('pk_test_ElzxNQyu6gL6OqIhOGxqKHBh')
  }

  let elements = stripe.elements()
  let card = elements.create('card', { style: style })

  if (isPresent(cardElement)) {
    card.mount('#card-element')
    initializeStripeToken(stripe, card)
  }
}

const removeHiddenPaymentFormIfAny = () => {
  const hiddenPaymentForm = document.getElementById('hidden-payment-form')
  if (isPresent(hiddenPaymentForm)) { hiddenPaymentForm.remove() }
}

const initializeStripeToken = (stripe, card) => {
  const stripeForm = document.getElementById('toggle-stripe-form')
  const form = document.getElementById('payment-form')
  const patientSubmitBtn = document.getElementById('patient-submit-btn')
  const patientStripeBtn = document.getElementById('patient-stripe-btn')



  patientStripeBtn.addEventListener('click', (event) => {
    if (patientStripeBtn.dataset.isDemoCc == 'true') { stripeForm.classList.add('hidden') } // hide stripe form for demo cc accounts
    stripe.createToken(card).then( (result) => {
      if (isPresent(stripeForm) && isVisible(stripeForm)) {
        if (result.error) {
          const errorElement = document.getElementById('card-errors')
          errorElement.textContent = result.error.message
          if (hasEmptyRequiredFields()) {
            return
          } else {
            alertErrorFlash('Payment Method Required.')
          }
        } else {
          stripeTokenHandler(result.token, form)
          if (form.classList.contains('has-error-border')) {
            form.classList.remove('has-error-border')
          }
          patientSubmitBtn.click()
        }
      } else {
        patientSubmitBtn.click()
      }
    })
  })

}

const stripeTokenHandler = (token, form) => {
  removeHiddenPaymentFormIfAny()
  const hiddenInput = document.createElement('input')
  hiddenInput.setAttribute('type', 'hidden')
  hiddenInput.setAttribute('name', 'card[stripe_card_token]')
  hiddenInput.setAttribute('value', token.id)
  hiddenInput.setAttribute('id', 'hidden-payment-form')
  form.append(hiddenInput)
}