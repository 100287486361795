import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'accountsList', 'display', 'selectAll', 'selectAllLabel', 'dropdown', 'monthSelect', 'yearSelect', 'viewBtn', 'accountItem']

  connect() {
    let avoidTargetArray = [this.displayTarget, this.inputTarget, this.selectAllTarget, this.selectAllLabelTarget]
    document.addEventListener('click', (e) => {
      const removeBtn = document.querySelectorAll('.remove-item-btn')
      if (removeBtn.length > 0) {
        const newRemoveBtns = Array.from(removeBtn).filter((btn) => !avoidTargetArray.includes(btn))
        avoidTargetArray.push(...newRemoveBtns)
      }
      if ( this.dropdownTarget.dataset.open == 'true' && (this.accountsListTarget != e.target.parentNode && !avoidTargetArray.includes(e.target))) {
        if (this.displayTarget.children.length == 0) {
          this.resetPlaceholder()
        }
        this.hideOpticDropdown()
      }
      if( e.target.classList.contains('remove-item-btn')) {
        avoidTargetArray.splice(avoidTargetArray.indexOf(e.target),1)
      }
      if( (e.target == this.selectAllLabelTarget || e.target == this.selectAllTarget) && !this.selectAllTarget.checked){
        avoidTargetArray = avoidTargetArray.filter( avoidTarget => !avoidTarget.classList.contains('remove-item-btn'))
      }
    })
  }

  hideOpticDropdown(){
    this.dropdownTarget.classList.add('hidden')
    this.dropdownTarget.dataset.open = 'false'
  }

  resetPlaceholder(){
    this.displayTarget.textContent = this.inputTarget.placeholder
    this.inputTarget.placeholder = ''
  }

  selectAllAccounts() {
    if (event.target.checked) {
      this.displayTarget.textContent = ''
      this.accountItemTargets.forEach((item) => {
        this.displayAccount(item)
      })
    } else {
      if (this.dropdownTarget.dataset.open == 'false') {
        this.displayTarget.textContent = 'Click to select or type in to look up account(s)...'
      } else {
        this.displayTarget.textContent = ''
      }
      this.accountItemTargets.forEach((item) => {
        item.classList.remove('account-selected')
      })
    }
    this.findSelectedAccount()
  }
  
  findSelectedAccount() {
    let accountsArray = []
    const opticAccountsList = document.querySelectorAll('.optic-accounts-display-section li')
    this.setAccountsArray(opticAccountsList, accountsArray)
    if (accountsArray.length > 0) {
      this.setDataParams(accountsArray.join(','))
      this.viewBtnTarget.classList.remove('disabled')
      this.monthSelectTarget.removeAttribute('disabled')
      this.yearSelectTarget.removeAttribute('disabled')
    } else {
      this.disableFilterOptions()
    }
  }

  disableFilterOptions() {
    this.viewBtnTarget.classList.add('disabled')
    this.monthSelectTarget.setAttribute('disabled', true)
    this.monthSelectTarget.value = ''
    this.yearSelectTarget.setAttribute('disabled', true)
    this.yearSelectTarget.value = ''
  }

  setAccountsArray(el, accountsArray) {
    for (let i = 0; i < el.length; i++) {
      accountsArray.push(el[i].dataset.id)
    }
  }

  setDataParams(accounts) {
    let reportFilterObj = new Map()
    const year = this.yearSelectTarget.value
    const month = this.monthSelectTarget.value
    reportFilterObj.set('accounts', accounts)
    reportFilterObj.set('year', year)
    reportFilterObj.set('month', month)
    this.viewBtnTarget.setAttribute('data-params', `options=${JSON.stringify(Array.from(reportFilterObj))}`)
  }

  getFilterOption() {
    let accountsArray = []
    accountsArray = JSON.parse(this.viewBtnTarget.dataset.params.split('=').pop())[0][1]
    this.setDataParams(accountsArray)
  }

  clearReports() {
    const reportTableEl = document.querySelector('#reports-table table')
    const paginateInfo = document.querySelectorAll('#reports-table .paginate-info')
    const noReport = document.querySelector('.no-report')
    if (isPresent(reportTableEl)) {
      reportTableEl.remove()
      paginateInfo[paginateInfo.length - 1].remove()
    }
    if (isPresent(noReport)) {
      noReport.innerText = ''
    }
  }

  fuzzySearch() {
    const noResult = document.getElementById('no-result')
    let searchInputs = this.inputTarget.value.split(' ')
    let filterAccounts = []
    let nonFilterAccounts = []
    if (!noResult.classList.contains('hidden')){
      noResult.classList.add('hidden')
    }
    Array.from(this.accountItemTargets).forEach((account) => {
      let termMatchCounter = 0
      searchInputs.forEach((term) => {
        if (!account.textContent.toLowerCase().includes(term.toLowerCase())){
          nonFilterAccounts.push(account)
          return
        } else {
          termMatchCounter++
        }
      })
      if(termMatchCounter === searchInputs.length) {
        filterAccounts.push(account)
      }
    })
    
    filterAccounts.forEach(filter => filter.classList.remove('hidden'))
    nonFilterAccounts.forEach( nonFilter => nonFilter.classList.add('hidden'))
    if (filterAccounts.length == 0) {
      noResult.classList.remove('hidden')
    }
  }

  selectAccount() {
    const foundSelectedItem = Array.from(this.displayTarget.children).filter( item => item.dataset.id == event.target.dataset.id)[0]
    if (!foundSelectedItem){
      this.displayAccount(event.target)
    } else {
      this.displayTarget.removeChild(foundSelectedItem)
      event.target.classList.remove('account-selected')
    }
    this.findSelectedAccount()
  }

  displayAccount(selectedAccount) {
    const selectListItem = document.createElement('li')
    selectListItem.textContent = selectedAccount.textContent
    selectListItem.setAttribute('data-id', selectedAccount.dataset.id)
    this.createRemoveBtn(selectListItem)
    selectedAccount.classList.add('account-selected')
    this.displayTarget.appendChild(selectListItem)
  }

  createRemoveBtn(selectListItem) {
    const removeBtn = document.createElement('button')
    removeBtn.innerHTML = '&times;'
    removeBtn.setAttribute('data-action', 'optics-report#removeListItem')
    removeBtn.classList.add('remove-item-btn')
    selectListItem.appendChild(removeBtn)
  }

  removeListItem() {
    const selectedItem = event.target.closest('li')
    Array.from(this.accountItemTargets).filter(item => item.dataset.id == selectedItem.dataset.id)[0].classList.remove('account-selected')
    selectedItem.remove()
    if (this.displayTarget.children.length == 0 && this.dropdownTarget.dataset.open == 'false'){
      this.displayTarget.textContent = 'Click to select or type in to look up account(s)...'
      this.disableFilterOptions()
    } else {
      this.findSelectedAccount()
    }
  }

  openAccountsList() {
    if (this.dropdownTarget.dataset.open == 'false') {
      if (!event.target.classList.contains('remove-item-btn')) {
        this.dropdownTarget.classList.remove('hidden')
        this.dropdownTarget.dataset.open = 'true'
        this.inputTarget.focus()
        this.inputTarget.setAttribute('placeholder', 'Click to select or type in to look up account(s)...')
        if (this.displayTarget.children.length == 0) {
          this.displayTarget.textContent = ''
        }
      }
    } else {
      if (this.displayTarget.children.length == 0 && !event.target.classList.contains('remove-item-btn')) {
        this.resetPlaceholder()
        this.hideOpticDropdown()
      } 
    }
  }
}