import { Controller } from "@hotwired/stimulus"
import { 
  validateWebToPatientProductForm, 
  checkProductForm, 
  promoProductEligible, 
  promoNotShown 
} from "../concerns/product_validation.js"
import { 
  addErrorBorder, 
  enableDisableNextDayNotice, 
  orderOriginPresent, 
  orderOriginIsSto, 
  orderOriginIsT2oStp, 
  orderOriginIsWtp, 
  removeErrorBorder, 
  toggleOffElementById, 
  toggleOnElementById, 
  submitOrder } from "../concerns/patient_shared.js"

export default class extends Controller {
  static targets = ['addToCart']

  connect() {
    if (!orderOriginPresent()) return
    // this.enableDisableNextDayNotice()
    this.enableDisableAddressInput()
  }

  validateForm() {
    if (orderOriginIsSto()) { return; }
    this.enableDisableAddressInput('1')
    if ((orderOriginIsT2oStp() || orderOriginIsWtp()) && !this.patientIsPresent()) return
    this.addressClearErrors()
    this.trimAddressInputs()
    this.checkForRequiredInput()
    this.showFlashIfErrors()
    if (!isPresent(document.querySelector('.has-error-border'))){
      this.addPatientInfoToEye()
    }
    // if (document.getElementById('address-fields').querySelectorAll('.has-error-border').length < 1 && this.addressValidatedOrSkipped()) {
    //   validateProductForm()
    // }
  }

  validateProductForm(e) {
    e.preventDefault();
    const clkAccount = this.addToCartTarget.dataset.clk
    const productFormInvalid = checkProductForm(clkAccount)
    if (productFormInvalid) {
      e.stopImmediatePropagation()
      return
    }
    if (promoProductEligible() && promoNotShown(this.addToCartTarget)) {
      $('#vsp_modal').modal("show");
      e.stopImmediatePropagation()
    } else {
      submitOrder()
    }
  }

  addressClearErrors() {
    if (document.getElementById('verify_address').checked || document.getElementById('order_do_not_validate').value == '1' || document.getElementById('order_address_validated').value == '1' || (isPresent(document.getElementById('confirmed_address')) && document.getElementById('confirmed_address').checked)) {
      document.getElementById('address-fields').style.border = "1px solid rgba(0, 0, 0, 0.125)"
      removeErrorBorder(document.getElementById('address-fields'))
    }
  }



  // enableDisableNextDayNotice() {
  //   if (!isPresent(document.getElementById('next_day_notice'))) return
  //   if (document.getElementById('order_shipping_method').value == 'P') {
  //     toggleOnElementById('next_day_notice')
  //   } else {
  //     toggleOffElementById('next_day_notice')
  //   }
  // }

  enableDisableAddressInput(create) {
    const orderAddressEl = document.getElementById('order_address_validated')
    if (!isPresent(orderAddressEl)) return
    const noEditAndValidated = orderAddressEl.value == "1" && getParams().edit != "true"
    for (let i = 0; i < this.orderIdsArray().length; i++) {
      const element = document.getElementById(`${ this.orderIdsArray()[i] }`)
      let disable = noEditAndValidated ? true : ''
      element.disabled = disable
      if (create == '1') { element.disabled = '' }
    }
  }

  orderIdsArray() {
    return [
      "order_patient_first_name", 
      "order_patient_last_name", 
      "order_name_2", 
      "order_street_address_1", 
      "order_street_address_2", 
      "order_city", 
      "order_zip", 
      "order_state"
    ]
  }

  trimAddressInputs() {
    if (this.addressExist()) {
      const requireInput = document.querySelectorAll(".require_address")
      requireInput.forEach(input => {
        if (input.value != null) {
          input.value = input.value.trim()
        }
      })
    }
  }

  checkForRequiredInput() {
    const addressInputArray = document.querySelectorAll('.require_address')
    if (this.addressExist()) {
      addressInputArray.forEach(input => {
        if (input.value.length < 1) {
          addErrorBorder(input)
        } else {
          removeErrorBorder(input)
        }
      })
      document.getElementById('address-fields').style.border = "1px solid rgba(0, 0, 0, 0.125)"
      removeErrorBorder(document.getElementById('address-fields'))
    }
  }

  addPatientInfoToEye() {
    const patientInfo = document.querySelector('input#order_patient_info').value
    document.querySelectorAll('input.order-detail-patient-info').forEach(input => {
      const eye = input.getAttribute('data-eye')
      input.value = `(${this.capitalize(eye)}) ${patientInfo}`
    })
  }

  showFlashIfErrors() {
    const error = document.getElementById('address-fields').querySelector('.has-error-border')
    let flashMsg
    if (isPresent(error)) {
      flashMsg = "Please fill in all required fields"
      this.checkFlashAlert(flashMsg)
      return
    }

    if (!this.verifyZipCodeLength()) {
      flashMsg = "Please review the ZIP" 
      this.checkFlashAlert(flashMsg)
      return
    }

    if (!this.addressValidatedOrSkipped()) {
      flashMsg = "Please skip or validate the address." 
      this.checkFlashAlert(flashMsg)
      return
    }
  }

  verifyZipCodeLength() {
    if (this.addressExist()) {
      const zipId = document.getElementById("order_zip")
      if (zipId.value.length < 5 || zipId.value.length > 10) {
        addErrorBorder(zipId)
        return false
      } else {
        return true
      }
    }
  }

  addressValidatedOrSkipped() {
    if (document.getElementById('order_do_not_validate').value != "1" && document.getElementById('order_address_validated').value != "1") {
      document.getElementById('address-fields').style.border = "solid 2px"
      addErrorBorder(document.getElementById('address-fields'))
      return false
    }
    this.addressClearErrors()
    return true
  }

  checkFlashAlert(flashMsg) {
    event.preventDefault()
    scrollToThis("#address-fields")
    alertErrorFlash(flashMsg)
  }

  capitalize(word) {
    return word[0].toUpperCase() + word.slice(1).toLowerCase()
  }

  addressExist() {
    return isPresent(document.getElementById('address-fields'))
  }

  patientIsPresent() {
    const stpTxtToOrderCheckbox = document.getElementById('enable_txt2order')
    if (isPresent(stpTxtToOrderCheckbox)) {
      if (!isPresent('order_origin') || !stpTxtToOrderCheckbox.checked) return
    }

    const patientId = document.getElementById('order_patient_id')
    const selectPatient = document.getElementById('selected_patient_name').closest('div.card')
    if (isPresent(patientId.value)) { 
      removeErrorBorder(selectPatient)
      return true 
    }
    
    addErrorBorder(selectPatient)
    scrollToThis('#patient-search')
    alertErrorFlash('Please select a patient.')
    event.preventDefault()
    return false
  }

}