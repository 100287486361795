import { Controller } from "@hotwired/stimulus"
//import TomSelect      from "tom-select"
// 2022-12-31 MDM found a working example TomSelect import here https://github.com/orchidjs/tom-select/discussions/160

import TomSelect from 'tom-select/dist/esm/tom-select.js'
import TomSelect_dropdown_input from 'tom-select/dist/esm/plugins/dropdown_input/plugin.js'
import TomSelect_checkbox_options from 'tom-select/dist/esm/plugins/checkbox_options/plugin.js'
import TomSelect_remove_button from 'tom-select/dist/esm/plugins/remove_button/plugin.js'
TomSelect.define('dropdown_input', TomSelect_dropdown_input);
TomSelect.define('checkbox_options', TomSelect_checkbox_options);
TomSelect.define('remove_button', TomSelect_remove_button);

// Connects to data-controller="ts--select"
export default class extends Controller {
  
  connect() {
    let ts = new TomSelect(this.element, {
      plugins: ['dropdown_input', 'checkbox_options', 'remove_button'],
    })
  }

}
