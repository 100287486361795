import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {

  saveLocations() {
    let locationsArray = []
    const locationsArrayEl = document.getElementById('location-array')
    const locations = document.getElementsByClassName('location_assign')
    const checkedLocations = document.querySelectorAll(".location_assign input[type=checkbox]:checked")
    for (let i = 0; i < checkedLocations.length; i++) {
      locationsArray.push(checkedLocations[i].closest('tr').getAttribute('data-account-id'))
    }
    locationsArrayEl.setAttribute('data-locations', locationsArray)
    this.updateLocations(this.element.getAttribute('data-user-id'), locationsArrayEl.getAttribute('data-locations'))
  }

  async updateLocations(userId, locationsArray) {
    const request = new FetchRequest('POST', '/toggle_assignments', {
      body: { 
        id: userId,
        locations: locationsArray 
      },
      responseKind: "json"
    })
    const response = await request.perform()
    const data = await response.json

    if (response.ok) { 
      alertSuccessFlash(data.message)
      window.location.href = `/users/child_users/${userId}/edit`
    } else {
      alertErrorFlash(data.message)
    }
  }

  selectAll() {
    const checkBoxes = document.querySelectorAll('.location_assign input[type=checkbox]')
    const checkedBoxes = document.querySelectorAll('.location_assign input[type=checkbox]:checked')
    if (checkedBoxes.length == checkBoxes.length) {
      for (let i = 0; i < checkBoxes.length; i++) {
        checkBoxes[i].checked = false
      }
    } else {
      for (let i = 0; i < checkBoxes.length; i++) {
        checkBoxes[i].checked = true
      }
    }
  }

}