import { Controller } from "@hotwired/stimulus"
import { addErrorBorder, removeErrorBorder, toggleOnElementById, toggleOffElementById } from '../concerns/patient_shared.js'

export default class extends Controller {
  toggleResourceAsset() {
    if (event.target.id == 'add-url-link') { return this.toggleOnUrlInput() }
    if (event.target.id == 'upload-pdf') { return this.toggleOnPdfInput() }
  }

  validateUrlLink() {
    const link = document.getElementById('resource-url-link')
    const testBtn = document.getElementById('url-test-link')
    const submitAssetBtn = document.getElementById('resource-submit-asset')
    if (link.value.includes('https://') || link.value.includes('http://')) { return this.urlIsValid(link, testBtn, submitAssetBtn) }
    if (link.value.length < 1) { return this.urlIsBlank(link, testBtn, submitAssetBtn) }
    this.urlIsInvalid(link, testBtn, submitAssetBtn)
  }

  toggleOnUrlInput() {
    if (event.target.checked) {
      toggleOnElementById('add-url-asset')
      toggleOffElementById('upload-asset')
      document.getElementById('upload-pdf').checked = false
      document.getElementById('resource_use_link').value = 1
    } else {
      toggleOffElementById('add-url-asset')
      document.getElementById('resource_use_link').value = 0
    }
  }

  toggleOnPdfInput() {
    if (event.target.checked) {
      toggleOnElementById('upload-asset')
      toggleOffElementById('add-url-asset')
      document.getElementById('add-url-link').checked = false
      document.getElementById('resource_use_link').value = 0
    } else {
      toggleOffElementById('upload-asset')
    }    
  }

  urlIsValid(link, testBtn, submitAssetBtn) {
    testBtn.setAttribute("href", link.value.trim())
    testBtn.classList.remove('disabled')
    submitAssetBtn.removeAttribute('disabled')
    removeErrorBorder(link)
  }

  urlIsBlank(link, testBtn, submitAssetBtn) {
    removeErrorBorder(link)
    testBtn.classList.add('disabled')
    submitAssetBtn.setAttribute('disabled', 'disabled')    
  }

  urlIsInvalid(link, testBtn, submitAssetBtn) {
    testBtn.setAttribute('href', '')
    testBtn.classList.add('disabled')
    submitAssetBtn.setAttribute('disabled', 'disabled')
    alertErrorFlash('Please input a valid URL using http:// or https://.')
    addErrorBorder(link)
    link.focus()
    event.preventDefault()    
  }
}