import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'
import { enableDisableNextDayNotice, toggleOnElementById, toggleOffElementById, clearPatientForm, orderOriginIsWtp, resetTax } from '../concerns/patient_shared.js'

export default class extends Controller {

  setOrderType() {
    // update hidden field element value
    let orderOrigin = document.getElementById('order_origin')
    orderOrigin.value = this.orderType
    // reset other checkboxes if they are currently checked
    this.resetOtherCheckboxes()
    // if enrollment order type, unhide the next order date section

    this.toggleOrderTypes()
  }

  formSubmitPath() {
    let form = document.getElementById('order_form')
    let orderId = document.getElementById('submit_order_form').getAttribute('data-order-id')
    this.validateNextOrderDate(form)
    let orderOrigin = document.getElementById('order_origin').value

    if (orderOrigin == 'wisvis_es_wtp') {
      form.setAttribute('action', `/complete_wtp_eyesubscribe_order/${orderId}`)
    } else if (orderOrigin == 'wisvis_t2o_wtp') {
      form.setAttribute('action', `/complete_wtp_text2order_order/${orderId}`)
    } else {
      form.setAttribute('action', `/web_to_patient_orders/${orderId}`)
    }
  }

  validateNextOrderDate(form) {
    let nextOrderDate = document.getElementById('order_next_order_date').value
    let today = new Date()
    if (isPresent(nextOrderDate) && nextOrderDate != "") {
      if (this.nextOrderDateWithinFiveDays(nextOrderDate, today)) {
        if (!confirm('You have initiated a delayed EyeSubscribe enrollment. However, your start date is within the next 5 days. Are you sure you would like to wait until the desired date to start the order?')) {
          event.preventDefault()
        }
      }
    }
  }

  nextOrderDateWithinFiveDays(nextOrderDate, today) {
    let week =  new Date(today.setDate(today.getDate() + 5)).toLocaleDateString()
    let startDate = new Date(nextOrderDate).toLocaleDateString()
    return startDate <= week
  }

  resetOtherCheckboxes() {
    let checkedInputs = this.element.querySelectorAll('input[type="checkbox"]:checked')
    this.noUncheck(checkedInputs)
    for(var i = 0; i < checkedInputs.length; i++) {
      if(checkedInputs[i].value != this.orderType){
        checkedInputs[i].checked = false
      }
    }
  }

  get orderType() {
    return event.target.value
  }

  noUncheck(checkedInputs) {
    // don't allow uncheck 
    if(checkedInputs.length == 0) {
      event.preventDefault()
    }
  }

  toggleOrderTypes() {
    let textToOrderNextOrderDate = document.getElementById('text-to-order-next-order-date')
    let eyesubscribeNextOrderDate = document.getElementById('eyesubscribe-next-order-date')
    let textToOrderTitle = document.getElementById('text-to-order-title')
    let eyesubscribeTitle = document.getElementById('eyesubscribe-title')
    let yourstoreTitle = document.getElementById('yourstore-title')
    const togglePatientCheck = document.getElementById('toggle_ordertype_patient_check')
    toggleOffElementById('patient-text-asterisk')

    if(this.orderTypeIsTextToOrder()) {
      this.hideClasses([eyesubscribeNextOrderDate, eyesubscribeTitle, yourstoreTitle])
      this.showClasses([textToOrderNextOrderDate, textToOrderTitle])
      toggleOnElementById('patient-text-asterisk')
    } else if (this.orderTypeIsEyesubscribe()) {
      this.showClasses([eyesubscribeNextOrderDate, eyesubscribeTitle])
      this.hideClasses([textToOrderNextOrderDate, textToOrderTitle, yourstoreTitle])
    } else {
      this.showClasses([yourstoreTitle])
      this.hideClasses([textToOrderNextOrderDate, textToOrderTitle, eyesubscribeNextOrderDate, eyesubscribeTitle])
    }

    if ( togglePatientCheck.dataset.value != undefined) {
      clearPatientForm(true)
      togglePatientCheck.click()
    }
  }

  hideClasses(elementsArray){
    elementsArray.forEach(function(element) {
      element.classList.add('hidden')
    })
  }

  showClasses(elementsArray){
    elementsArray.forEach(function(element) {
      element.classList.remove('hidden')
    })
  }

  setPath() {
    let orderForm = document.getElementById('order_form')
    
    // if(this.orderTypeIsTextToOrder()) {
    //   // will need to be updated if controller names change
    //   orderForm.setAttribute('action','/text_to_orders')
    // } else if (this.orderTypeIsEyesubscribe()) {
    //   orderForm.setAttribute('action', '/eyesubscribes')
    // } 
  }
  
  orderTypeIsTextToOrder() {
    return this.orderType == 'wisvis_t2o_wtp'
  }

  orderTypeIsEyesubscribe() {
    return this.orderType == 'wisvis_es_wtp'
  }

  
  clearAddressForm() {
    const addressFields = document.querySelectorAll('#address-fields input')
    for(let i = 0; i < addressFields.length; i++) {
      addressFields[i].value = ''
    }
  }

  updateTax() {
    if (!orderOriginIsWtp()) {return}
    resetTax()
  }
  
  async updateShipMethod() {
    const orderId = event.target.dataset.id
    const shippingMethod = event.target.value
    const request = new FetchRequest('patch', '/update_shipping', { body:{ id: orderId, order: { shipping_method: shippingMethod } }, responseKind: 'json' })
    const response = await request.perform()
    const data = await response.json
    if (data) {
      alertInfoFlash("Shipping method updated.")
      enableDisableNextDayNotice()
      if (orderOriginIsWtp()){
        this.updateRetailCartPrices(data)
      }
    }
  }

  updateRetailCartPrices(data) {
    const freight = document.querySelector('.retail_freight_cost')
    const total = document.querySelector('.retail_total_cost')
    freight.innerText = `$${parseFloat(data.freight).toFixed(2)}`
    total.innerText  = `$${parseFloat(data.retail_total).toFixed(2)}`
  }
}

