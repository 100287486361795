import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "button" ]

  handleRequest(event) {
    event.preventDefault();

    const path = this.buttonTarget.dataset.href;
    const method = this.buttonTarget.dataset.method;
    this.sendRequest(path, method)
      .then(() => {
        const disableWithController = this.application.getControllerForElementAndIdentifier(this.element, "disable-with");
        if (disableWithController) disableWithController.enable();
      });
  }

  sendRequest(path, method) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    return fetch(path, {
      method: method,
      headers: {
        'X-Requested-With': 'XMLHttpRequest', // To mimic Rails UJS
        'Accept': 'text/javascript',
        'Content-Type': 'text/javascript',
        'X-CSRF-Token': csrfToken
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`Unexpected HTTP error! status: ${response.status}`);
      }
      return response.text();
    })
    .then(js => {
      eval(js); // if the response is a script, evaluate it
    })
    .catch(error => {
      console.error('Fetch error:', error);
    });
  }
}
